import styled from 'styled-components';

export const Select = styled.select`
  display: block;
  flex: 1;
  color: inherit;
  font-size: 16px;
  padding: 1rem;
  background: ${({ theme }) => theme.colors.glassBackgroundCalc};
  backdrop-filter: blur(4px);
  -webkit-backdrop-filter: blur(4px);
  border-radius: 5px;
  -webkit-appearance: none;
  height: 4rem;
  border: none;

  > option {
    background: ${({ theme }) => theme.colors.tableBackground};
    color: ${({ theme }) => theme.colors.text};
  }
`;
