import { darken, rgba } from 'polished';

import React from 'react';
import dynamic from 'next/dynamic';
import styled from 'styled-components';
import RainbowThemeContainer from 'react-rainbow-components/components/RainbowThemeContainer'
import useTheme from '../../../../utils/useTheme';

const RainbowDatePicker = dynamic(
  () => import('react-rainbow-components/components/DatePicker'),
  {
    ssr: false,
    loading: () => <div style={{ minHeight: '47px' }}></div>,
  }
);

const StyledDatePicker = styled(RainbowDatePicker)`
  font-size: 1.4rem;

  > div:first-child > div > input {
    display: block;
    border: none;
    flex: 1;
    color: inherit;
    font-size: 16px;
    padding: 10px;
    background: ${({ theme }) => theme.colors.glassBackgroundCalc};
    backdrop-filter: blur(4px);
    -webkit-backdrop-filter: blur(4px);
    border-radius: 5px;
    height: 4rem;
    :disabled {
      background: ${rgba(darken(0.4, 'rgb(255, 255, 255)'), 0.5)};
    }
  }
`;


const DatePicker = React.forwardRef(function DatePicker(
  { id, help, ...props },
  ref
) {
  const theme = useTheme();

  return (
    <RainbowThemeContainer  theme={{
      rainbow: {
        palette: {
          brand: theme.colors.primary,
        },
      },
    }}>
      <StyledDatePicker id={id} ref={ref} bottomHelpText={help} {...props} />
    </RainbowThemeContainer>
  );
});

export default DatePicker;
