import { Box, Composition } from 'atomic-layout';
import React, { useEffect, useRef, useState } from 'react';
import { Tooltip, TooltipButtonWrapper, TooltipWrapper } from './tooltip';
import { darken, rgba } from 'polished';

import { RiInformationLine } from 'react-icons/ri';
import styled from 'styled-components';

export { Select } from './select';
export { default as DatePicker } from './datepicker';

export const Label = styled.label(({ isDisabled }) => ({
  opacity: isDisabled ? 0.5 : 1,
}));
export const ErrorText = styled.span`
  color: ${({ theme }) => theme.colors.warningText};
`;
export const SupplementaryText = styled.span`
  color: ${({ theme }) => theme.colors.primary};
`;

export const Input = styled.input`
  font-size: 16px;
  display: block;
  flex: ${({ $flex }) => ($flex ? $flex : 1)};
  color: inherit;
  font-size: 16px;
  padding: 1rem;
  background-color: ${({ $transparent, theme }) =>
    $transparent ? 'transparent' : theme.colors.glassBackgroundCalc};
  backdrop-filter: blur(4px);
  -webkit-backdrop-filter: blur(4px);
  border-radius: 5px;
  border: none;
  width: ${({ $width }) => ($width ? $width : '100%')};
  text-align: ${({ $textAlign }) => ($textAlign ? $textAlign : 'auto')};

  &:disabled {
    background-color: ${({ $transparent, theme }) =>
      $transparent ? 'transparent' : theme.colors.glassBackgroundCalc};
    cursor: not-allowed;
  }

  &[type='number'] {
    -moz-appearance: textfield;
  }
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;

export const Textarea = styled.textarea`
  display: block;
  flex: ${({ $flex }) => ($flex ? $flex : 1)};
  color: inherit;
  padding: 1rem;
  background: ${({ theme }) => theme.colors.glassBackgroundCalc};
  backdrop-filter: blur(4px);
  -webkit-backdrop-filter: blur(4px);
  border-radius: 5px;
  width: ${({ $width }) => ($width ? $width : 'initial')};
  text-align: ${({ $textAlign }) => ($textAlign ? $textAlign : 'auto')};
  font-family: ${({ theme }) => theme.typography.bodyFontFamily};
  font-size: 16px;

  :disabled {
    background: ${rgba(darken(0.4, 'rgb(255, 255, 255)'), 0.5)};
    cursor: not-allowed;
  }

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  min-height: 100px;
`;

const fieldAreas = `
label label
input input
error supplementaryText
`;

export const StyledField = ({
  id,
  children,
  label,
  error,
  help,
  upperRightContent,
  supplementaryText,
  isDisabled,
  ...props
}) => {
  const [showTooltip, setShowTooltip] = useState(false);

  return (
    <div style={{ width: '100%' }}>
      <TooltipWrapper>
        <Tooltip $show={showTooltip}>{help}</Tooltip>
      </TooltipWrapper>
      <Composition areas={fieldAreas} gap={1} {...props}>
        {(Areas) => (
          <>
            <Areas.Label flex flexDirection="row" alignItems="center">
              <Box flexGrow={1}>
                <Label isDisabled={isDisabled} htmlFor={id}>
                  {label}
                </Label>
              </Box>
              <Box style={{ paddingRight: "10px" }}>{upperRightContent}</Box>
              {help && (
                <TooltipButtonWrapper
                  isDisabled={isDisabled}
                  onMouseOver={() => setShowTooltip(true)}
                  onMouseLeave={() => setShowTooltip(false)}
                >
                  <RiInformationLine />
                </TooltipButtonWrapper>
              )}
            </Areas.Label>
            <Areas.Input flex>{children}</Areas.Input>
            {error && (
              <Areas.Error flexGrow={1}>
                <ErrorText>{error}</ErrorText>
              </Areas.Error>
            )}
            {supplementaryText && !error && (
              <Areas.SupplementaryText
                flex
                flexGrow={1}
                justifyContent="flex-end"
              >
                <SupplementaryText>{supplementaryText}</SupplementaryText>
              </Areas.SupplementaryText>
            )}
          </>
        )}
      </Composition>
    </div>
  );
};

const WrapperDiv = styled.div(({ theme, $hidden, isDisabled }) => ({
  opacity: isDisabled ? 0.5 : 1,
  display: $hidden ? 'none' : 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  gap: '1rem',
  width: '100%',
  border: 'none',
  flexShrink: 0,
}));

const Span = styled.span`
  display: flex;
  align-items: center;
  padding-left: ${({ $hasLeftContent }) => ($hasLeftContent ? '1rem' : '0')};
  padding-right: 1rem;
  background: ${({ $disabled, theme }) =>
    $disabled ? 'rgba(153, 153, 153, 0.5)' : theme.colors.glassBackgroundCalc};
  backdrop-filter: blur(4px);
  -webkit-backdrop-filter: blur(4px);
  border-radius: 5px;
  border: none;
  width: 100%;
  height: 3.9rem;
  margin: 0;
  font-size: 16px;
  color: ${({ $error, theme }) =>
    $error ? theme.colors.warningText : 'inherit'};
  border: ${({ $error, theme }) =>
    $error
      ? `0.1rem solid ${theme.colors.warningText}`
      : '0.1rem solid rgba( 255, 255, 255, 0.18 )'};
  > input {
    border: none;
    padding: 0px;
    padding-right: 0.5rem;
    padding-left: ${({ $hasLeftContent }) => ($hasLeftContent ? '0' : '1rem')};
    outline: none;
    background: transparent;
    backdrop-filter: initial;
    -webkit-backdrop-filter: initial;
    height: 100%;
    margin-right: ${({ $hasRightContent }) =>
      $hasRightContent ? '.5rem' : '0'};
    font-size: 16px;
  }
`;

export const InputWithPrefixSuffix = React.forwardRef(
  (
    { leftContent, rightContent, $hidden, $error, isDisabled, ...props },
    ref
  ) => (
    <WrapperDiv $hidden={$hidden} isDisabled={isDisabled}>
      <Span
        $hasLeftContent={!!leftContent}
        $hasRightContent={!!rightContent}
        $isDisabled={isDisabled}
        $error={$error}
      >
        {leftContent}
        <Input disabled={isDisabled} {...props} $transparent={true} ref={ref} />
        {rightContent}
      </Span>
    </WrapperDiv>
  )
);

export const FormattedInput = React.forwardRef(
  (
    {
      id,
      format,
      value,
      onChange,
      leftContentEditing,
      rightContentEditing,
      forcedDefault,
      forceNumeric,
      noFraction,
      noNegative,
      isDisabled,
      ...props
    },
    ref
  ) => {
    const editorRef = ref || useRef();
    const formattedRef = useRef();
    const [editing, setEditing] = useState(false);
    const [hasChanged, setHasChanged] = React.useState(false);

    useEffect(() => {
      if (editing) editorRef.current?.focus();
    }, [editing]);

    return (
      <>
        <InputWithPrefixSuffix
          isDisabled={isDisabled}
          id={id}
          ref={editorRef}
          {...props}
          value={value}
          onChange={onChange}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              e.preventDefault();
              editorRef.current?.blur();
            }
            if (noNegative && e.key === '-') {
              e.preventDefault();
              return false;
            }
            if (noFraction && e.key === '.') {
              e.preventDefault();
              return false;
            }
          }}
          onFocus={(e) => {
            if (!hasChanged) {
              e.target.value = '';
            }
          }}
          onBlur={(e) => {
            if (!hasChanged && e.target.value === '') {
              e.target.value = props.defaultValue;
            }
            if (typeof forcedDefault !== 'undefined') {
              if (e.target.value === '') {
                onChange({
                  target: {
                    value: forcedDefault,
                  },
                });
              }
            }
            if (forceNumeric && isNaN(e.target.value)) {
              onChange({
                target: {
                  value: forcedDefault || '',
                },
              });
            }
            if (forceNumeric) {
              onChange({
                target: {
                  value: e.target.value
                    ? Number.parseFloat(e.target.value).toString()
                    : forcedDefault || '',
                },
              });
            }
            setEditing(false);
          }}
          leftContent={leftContentEditing}
          rightContent={rightContentEditing}
          $hidden={!editing}
        />
        <InputWithPrefixSuffix
          id={`${id}-value`}
          ref={formattedRef}
          {...props}
          type="text"
          value={format(value)}
          readOnly
          onFocus={() => {
            if (!props.readOnly) {
              setEditing(true);
            }
          }}
          $hidden={editing}
          isDisabled={isDisabled}
        />
      </>
    );
  }
);

export const PercentInput = React.forwardRef(
  ({ $isDisabled, ...props }, ref) => {
    return (
      <FormattedInput
        ref={ref}
        $isDisabled={$isDisabled}
        {...props}
        format={(val) => `${val}%`}
      />
    );
  }
);
