import styled from 'styled-components';

export const TooltipButtonWrapper = styled.div(({ theme, isDisabled }) => ({
  opacity: isDisabled ? 0.5 : 1,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '0 0.5',
  ['> svg']: {
    height: '2rem',
    width: '2rem',
    fill: theme.colors.text,
  },
}));

export const TooltipWrapper = styled.div`
  position: relative;
  width: 100%;
`;

export const Tooltip = styled.div`
  display: ${({ $show }) => ($show ? 'inline-block' : 'none')};

  position: absolute;
  background: ${({ theme }) => theme.colors.primary};
  color: ${({ theme }) => theme.colors.textInverse};
  border-radius: 5px;

  width: 100%;
  max-width: calc(100vw - 4rem);
  padding: 2px 10px;
  white-space: pre-word;
  right: 0;
  bottom: 3px;
  z-index: 100;
`;
